import React from "react"
import MemberCard from "./MemberCard";
import AboutSection from "./AboutSection";
import JoinSection from "./JoinSection";
import {OhadaTeams, EditorialAdvisoryBoardTeam} from "../../util/constant";
import {useTranslation} from "gatsby-plugin-react-i18next";

const AboutContent = ({}: any) => {
    const {t} = useTranslation()


    return (
        <>
            <AboutSection/>
            <section className="wrapper bg-light">
                {OhadaTeams({t})
                    .map(team => {
                        return (
                            <MemberCard
                                key={team.name}
                                name={team.name}
                                title={team.title}
                                description={team.LongDescription}
                                imgUrl={team.imgURL || "/assets/img/teams/mod/avatar.png"}
                            />
                        )
                    })
                }
                {EditorialAdvisoryBoardTeam({t})
                    .map(team => {
                        return (
                            <MemberCard
                                key={team.name}
                                name={team.name}
                                title={team.title}
                                description={team.LongDescription}
                                imgUrl={team?.imgUrl || "/assets/img/teams/mod/avatar.png"}
                            />
                        )
                    })
                }
            </section>
            <JoinSection/>
        </>
    )
}
export default AboutContent
