import React, {useEffect, useState} from "react";
import Layout from "../components/layout/Layout";
import AboutContent from "../components/about/AboutContent";
import {loadScript, scrollToTargetDiv} from "../util/helper";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Helmet} from "react-helmet";
import {useLocation} from "@reach/router";

const About = ({}:any) => {


    const { t, i18n } = useTranslation()
    const location = useLocation()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        loadScript("/assets/js/plugins.js");
        const timer = setTimeout(() => {
            loadScript("/assets/js/theme.js");
            scrollToTargetDiv(location.hash.replace("#", ""))
        }, 200)

        setLoading(true)
        return ()=> {
            clearTimeout(timer);
            setLoading(false)
        }
    }, [loading])

    return <>
        <Helmet>
            <title>OHADA | {t('aboutUs')}</title>
        </Helmet>
        <Layout title={`OHADA |  ${t('aboutUs')}`}>
            {!loading && <div className="page-loader"></div> }
            <AboutContent />
        </Layout>
    </>
}
export default About;


export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
