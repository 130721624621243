import React from 'react';
import {useTranslation} from "gatsby-plugin-react-i18next";


const JoinSection = ({}:any) => {
    const { t } = useTranslation()

    return (
        <section className="wrapper image-wrapper bg-auto no-overlay bg-image text-center mb-14 mb-md-16 bg-map"
                 data-image-src="/assets/img/map.png">
            <div className="container py-md-18">
                <div className="row">
                    <div className="col-lg-6 col-xl-5 mx-auto">
                        <h2 className="display-4 mb-3 text-center">{t('Join Our Community')}</h2>
                        <p className="lead mb-5 px-md-16 px-lg-3">{t('joinMessage')}</p>
                        <a href="/app/contact" className="btn btn-primary rounded-pill">{t('Join Us')}</a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default JoinSection;
