import React from "react"

const AboutSection = ({}: any) => {

    return (
        <>
            <section className="wrapper image-wrapper bg-image bg-overlay text-white"
                     data-image-src="/assets/img/bg2.png">
                <div className="container pt-19 pt-md-21 pb-18 pb-md-20 text-center">
                    <div className="row">
                        <div className="col-md-10 col-lg-8 col-xl-7 col-xxl-11 mx-auto">
                            <h1 className="display-1 text-white mb-3">About Us</h1>
                            <p className="lead fs-lg px-md-3 px-lg-7 px-xl-8 px-xxl-2 text-justify">The objective of this platform is to promote OHADA arbitration throughout the world, by offering to practitioners and academics specializing in OHADA arbitration, a space for publishing articles in a journal and a blog accessible online.
                                In its objective to promote and popularize OHADA arbitration, the platform will put online the latest news on OHADA arbitration, in particular the legislative novelties and the case law.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AboutSection
